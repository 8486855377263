<template>
  <div id="product">
    <Header/>
    <div class="other__narrow__banner">
      <img  :src="'https://www.elevator.com.vn/upload/' + pageImage"  alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>{{pageTitle}}</h4>
        </div>
      </div>
    </div>

    <div v-for="a in item.prodData" class="other__ck__block img__two" v-bind:id="'p'+a.id" :key="a.id">
      <div class="container">
        <div v-if="a.parent_id !== 275" class="other__ck__title title__bar">
          <h2 v-html="a.title"></h2>
        </div>
        <div class="other__ck__text">
          <p v-html="a.descript">
            </p>
        </div>
        <div class="product_pic_items">
          <div class="product_pic_items_pic-wrapper" v-for="b in item.productImage.filter(x=>x.parent_id == a.id)" :key="b.id">
            <img :src="'/upload/' + b.image" alt="" />
          </div>
        </div>
        <div v-html="a.content">
        
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { apiProductData } from "@/api/pages.js";
import Header from './../components/Header'
export default {
  name: "Product",
  components: {
    Header
  },
  data(){
    return{
      pageTitle:'',
      pageImage:'',
      hash: '',
      p:{},
      item:{}
    }
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      location.reload();
    }
  },
  created(){
    this.p = this.$route.params
  },
  mounted: async function () {
    console.log(this.$route.params)
    this.p = this.$route.params
    const res = await apiProductData({ptid:this.p.ptid,psid:this.p.psid});
    this.item = res.data;
    console.log(this.item)
    if(this.item.prodType[0].id === 176){
      this.pageTitle = this.item.prodType[0].title
      this.pageImage = this.item.prodType[0].image
    }else{
      this.pageTitle = this.item.prodType[0].title + ' - ' + this.item.prodSerial[0].title
      this.pageImage = this.item.prodType[0].image
    }
    const target = `p${this.p.pdid}`
    var interval = setInterval( () => {
      var tar =  document.querySelector(`#${target}`)
      // console.log(tar)
      if (tar){
        clearInterval(interval)
        setTimeout(() => {
          document.querySelector(`#${target}`).scrollIntoView({behavior: "smooth"})
        }, 500);
      } else {
        clearInterval(interval)
        setTimeout(() => {
          document.querySelector('#product').scrollIntoView({behavior: "smooth"})
        }, 500);
      }
    }, 100);
  },
  methods: {
    // getData(){
      
    // }
  },
};
</script>
